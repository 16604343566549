/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/technology/bluetooth/'], ['en', 'https://www.hear.com/hearing-aids/technology/bluetooth/'], ['en-US', 'https://www.hear.com/hearing-aids/technology/bluetooth/'], ['en-CA', 'https://ca.hear.com/hearing-aids/technology/bluetooth/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "improve-your-quality-of-life-with-bluetooth-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improve-your-quality-of-life-with-bluetooth-hearing-aids",
    "aria-label": "improve your quality of life with bluetooth hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improve your quality of life with Bluetooth hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth hearing aids wirelessly connect to your smartphone, TV, computer, and more – allowing you to stream HD quality audio directly to your hearing aids. The future is finally “hear!”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern hearing aids improve your quality of life by delivering brilliant sound quality during conversations. Bluetooth hearing aids, on the other hand, do that and deliver incredible sound quality streamed wirelessly from your electronic devices. In the past, hearing aids had trouble picking up sound from phone receivers. These sounds were over amplified and not fine-tuned, causing hearing aids to whistle."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "From enhanced sound quality to greater freedom and flexibility, today’s advanced hearing systems are supercomputers the size of a coffee bean. Bluetooth enabled hearings aids:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Enhance sound quality and reduce background noise"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Offer greater control, freedom, and convenience"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Allow you to stream audio from any Bluetooth-enabled device directly to your hearing aids"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "benefits-of-bluetooth-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#benefits-of-bluetooth-hearing-aids",
    "aria-label": "benefits of bluetooth hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Benefits of Bluetooth hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth connectivity does not make your hearing aids perform better, but rather, it allows you to connect your hearing aids with any Bluetooth-enabled electronic device and make their use easier for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With Bluetooth connectivity, you can stream audio directly to your hearing aids. Here are the main advantages:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Watching TV:"), " Those with hearing loss often understand the TV better with headphones. By streaming the TV audio directly to your hearing aids, you are not only eliminating any reverberation that may occur between the TV speakers and your ears, but you’re eliminating the need for headphones! You can also adjust the hearing aid volume from an easy-to-use App instead of on the TV."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Phone conversations:"), " Talking on the phone while wearing hearing aids can be difficult. And it isn’t always comfortable either, as you need to hold the speaker near the your device’s microphone. With some hearing aids, you might also hear an annoying whistling sound. Bluetooth hearing aids making talking on the phone easy and stress-free!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Listening to music:"), " Enjoy your favorite music streamed directly to your hearing aids, without wearing uncomfortable headphones!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Additionally, streaming audio from your electronic devices to your hearing aids will filter out background noise and allow you to fully enjoy the sounds you really want to hear, like your grandchild talking on the phone, without any whistling. You can also take a walk in the park while talking on the phone, not worrying about extraneous environmental sounds, or even talk on the phone at your local coffee shop without being bothered by background chatter. The possibilities are endless with Bluetooth-enabled hearing aids."), "\n", React.createElement(ButtonCta, {
    copy: "TRY BLUETOOTH HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-they-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-they-work",
    "aria-label": "how do they work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do they work?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bluetooh-lifestyle.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "T-Coils, also known as telecoils, are located inside hearing aids and receive FM signals (or electromagnetic signals) from a Bluetooth-enabled device (e.g. your smartphone or TV) via a streamer. When the T-coil receives a signal, the microphone in the hearing aid turns off. This reduces background interference and stops the whistling sound experienced during phone calls. Without extraneous sounds coming into your hearing aid, you can focus on the sounds coming from your phone, television or music player.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now, smartphones and televisions wirelessly connect to Bluetooth hearing aids either directly or through a small streamer device to deliver unparalleled sound quality. Some hearing aid manufacturers offer Bluetooth hearing aids that are compatible with Android smartphones and iPhones, creating a seamless connection to your electronic device. All manufacturers offer ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories",
    className: "c-md-a"
  }, "streamer accessories"), " which convert a Bluetooth signal from an electronic device into FM signals. Then, the streamer sends the FM signal to your hearing aids without signal disruptions. Both the streamer and direct connection options offer optimal clarity and ease of use."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth hearing aids wirelessly connect to: smartphones, tablets, computers, televisions, mp3 players."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth is very easy to use – simply turn it on or off with an easy-to-use app."), "\n", React.createElement(ButtonCta, {
    copy: "TRY BLUETOOTH HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bluetooth-gives-you-control-freedom-and-convenience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bluetooth-gives-you-control-freedom-and-convenience",
    "aria-label": "bluetooth gives you control freedom and convenience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bluetooth gives you control, freedom, and convenience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All hearing aids are programmed to meet your specific auditory needs. An audiologist will set your hearing aid settings by calibrating the frequencies, volume, and gain levels necessary for answering phone calls through your hearing aids via Bluetooth. This ensures a high-quality audio experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once you take home your new devices, you can continue to exercise maximum control over its settings through a smartphone App or streamer. With Bluetooth hearing aids that are compatible with iPhones or Android smartphones, you control the volume and other settings from the manufacturers’ Apps. If your hearing aid has a wireless connection to a streamer, you can adjust its settings using the streamer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "choosing-the-best-bluetooth-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#choosing-the-best-bluetooth-hearing-aid",
    "aria-label": "choosing the best bluetooth hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Choosing the best Bluetooth hearing aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we recommend any ", React.createElement(_components.a, {
    href: "/hearing-aids/signia",
    className: "c-md-a"
  }, "Signia"), " Bluetooth-enabled device. For Bluetooth connectivity to work properly, it is important that both hearing aids are connected and in constant communication with each other."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth connectivity does not make the device more expensive. However, this feature is not yet available on Basic hearing aids. For you to enjoy the benefits of a Bluetooth hearing aid, you’ll need to purchase a device that’s at the ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "Mid-Range or Premium technology level.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-expert-advice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-expert-advice",
    "aria-label": "hearcom expert advice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com expert advice"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We live in an increasingly connected world, and connecting your hearing aids to your electronic devices is now more important than ever. Bluetooth-enabled hearing aids make your life much easier. In the comfort of your home, you can easily switch from talking on your phone to watching television, as well as seamlessly listen to music directly from your hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com we are ready to answers all your questions about the latest Bluetooth-enabled hearing aids. From streaming accessories to Bluetooth hearing aids for iPhone or Android, we will go into greater detail about all the latest features of these incredible devices. We look forward to getting in touch with you soon."), "\n", React.createElement(ButtonCta, {
    copy: "TRY BLUETOOTH HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
